import React from 'react';
import { Form, FormInstance, Input, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { validateSlugField } from '@/utils/validations';
import { CatalogFormBody } from '@/interfaces/catalogs.interface';

export interface CatalogFormProps {
  formRef: FormInstance<CatalogFormBody>;
  isEdit?: boolean;
  target?: string;
  onSubmit?: (values: CatalogFormBody) => Promise<void>;
}

const CatalogForm: React.FC<CatalogFormProps> = ({
  formRef,
  target = 'category',
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      form={formRef}
      name="catalog-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
    >
      <Row gutter={[10, 10]}>
        <Col span={10} xs={10} md={10}>
          <Form.Item
            label={t('catalogs.name')}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={10} xs={10} md={10}>
          <Form.Item
            label={t('general.slug')}
            name="slug"
            rules={[
              {
                max: 30,
                min: 4,
                required: true,
                type: 'string',
                whitespace: true,
              },
              {
                validator: validateSlugField,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={4} xs={4} md={4}>
          <Form.Item
            label={t('general.language')}
            name="language"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              disabled={true}
              options={[
                {
                  label: t('languages.en'),
                  value: 'en',
                },
                {
                  label: t('languages.es'),
                  value: 'es',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      {target === 'category' && (
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('catalogs.fieldConfig')}
              name="field_config"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                options={[
                  {
                    label: t('general.slug'),
                    value: 'slug',
                  },
                  {
                    label: t('general.description'),
                    value: 'description',
                  },
                  {
                    label: t('general.icon'),
                    value: 'icon',
                  },
                  {
                    label: t('general.image'),
                    value: 'image',
                  },
                  {
                    label: t('general.color'),
                    value: 'color',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default CatalogForm;
