import { Breadcrumb, Card, Col, Divider, Row, Modal, message, Button } from 'antd';
import { HomeOutlined, BookOutlined } from '@ant-design/icons';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';
import CatalogsTable from '@/components/catalogs/CatalogsTable';
import CatalogsModal from '@/components/catalogs/CatalogsModal';
import { Catalog } from '@/interfaces/catalogs.interface';

const DynamicCategoriesScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
  });

  const [modal, setModal] = useState<{
    type: 'create' | 'edit';
    target: 'category' | 'translation';
    visible: boolean;
    data: any;
  }>({
    type: 'create',
    target: 'category', // translation
    visible: false,
    data: {},
  });
  const { isLoading, data, refetch } = useQuery(['catalogs', params], () =>
    APIService.getCatalogs(params),
  );

  const handleDelete = async (id: string) => {
    const response = await APIService.deleteCatalog(id);
    if (response.ok) {
      refetch();
      message.success(t('catalogs.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };

  const onDelete = (id: string) => {
    Modal.confirm({
      title: t('catalogs.deleteConfirmation'),
      okText: t('general.yes'),
      onOk: () => handleDelete(id),
    });
  };

  const onEdit = (data: Catalog) => {
    // Hanlde edit user, open edition modal

    setModal({
      visible: true,
      type: 'edit',
      target: 'category',
      data,
    });
  };

  return (
    <div className="dashboard-screen fadeIn">
      <CatalogsModal
        visible={modal.visible}
        edit={modal.type === 'edit'}
        data={modal.data}
        type={modal.target}
        onOk={() => {
          refetch();
          setModal({ visible: false, type: 'create', data: {}, target: 'category' });
        }}
        onCancel={() => {
          setModal({ visible: false, type: 'create', data: {}, target: 'category' });
        }}
      />
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <BookOutlined />
                      <span>{t('menu.catalogs')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setModal({
                  visible: true,
                  type: 'create',
                  data: {},
                  target: 'category',
                })
              }
            >
              {t('catalogs.create')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <CatalogsTable
          onFilter={setParams}
          data={data?.data}
          loading={isLoading}
          onModalChange={setModal}
          total={data?.total}
          current={(data?.skip || 0) / (data?.limit || 1)}
          {...{ onDelete, onEdit }}
        />
      </Card>
    </div>
  );
};

export default DynamicCategoriesScreen;
