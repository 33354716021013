import { Button, Table, Space, Row, Col } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';
import { Catalog, RawCatalog } from '@/interfaces/catalogs.interface';
import FlagIcon from '../ui/FlagIcon';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

export interface CatalogsTableProps {
  data?: RawCatalog[];
  total?: number;
  current?: number;
  loading?: boolean;
  onModalChange?: (value: {
    type: 'create' | 'edit';
    target: 'category' | 'translation';
    visible: boolean;
    data: any;
  }) => void;
  onFilter?: (value: any) => any;
  onEdit?: (data: Catalog) => void;
  onView?: (data: Catalog) => void;
  onDelete?: (id: string) => void;
}

const CatalogsTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
  onModalChange,
}: CatalogsTableProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const altLanguage = language === 'en' ? 'es' : 'en';

  const Columns: ColumnsType<RawCatalog> = [
    {
      title: t('catalogs.name'),
      dataIndex: 'configurations',
      key: 'name',
      render: (_id, row) => {
        const record = row.configurations.find((obj) => obj.language === language);
        return record?.name;
      },
    },
    {
      title: t('catalogs.slug'),
      dataIndex: '_id',
      key: 'slug',
      render: (_id, row) => {
        const record = row.configurations.find((obj) => obj.language === language);
        return record?.slug;
      },
    },
    {
      title: (
        <Row gutter={[10, 10]}>
          <Col>
            <FlagIcon code={altLanguage} />
          </Col>
          <Col>{altLanguage.toLocaleUpperCase()}</Col>
        </Row>
      ),
      dataIndex: '_id',
      key: 'language',
      render: (_id, row) => {
        const record = row.configurations.find((obj) => obj.language === altLanguage);
        return record ? (
          <EditOutlined
            style={{ fontSize: 20, cursor: 'pointer' }}
            onClick={() =>
              onModalChange?.({
                visible: true,
                type: 'edit',
                data: {
                  ...row,
                  ...record,
                },
                target: 'translation',
              })
            }
          />
        ) : (
          <PlusOutlined
            style={{ fontSize: 20, cursor: 'pointer' }}
            onClick={() =>
              onModalChange?.({
                visible: true,
                type: 'create',
                data: {
                  ...row,
                },
                target: 'translation',
              })
            }
          />
        );
      },
    },
    {
      title: t('catalogs.fieldConfig'),
      dataIndex: 'field_config',
      key: 'country',
      render: (field_config) => field_config.join(', '),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: RawCatalog) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (
    pagination: any = {},
    filters: any = {},
    sorter: any,
    { action }: { action: string },
  ) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...value,
        ...parseFilter(filters),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={Columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default CatalogsTable;
