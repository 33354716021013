import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { CatalogConfig, CatalogFormBody } from '@/interfaces/catalogs.interface';
// Services
import APIService from '@/services/API';
import CatalogForm from '../forms/CatalogForm';

export interface CatalogsModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, any>;
  type?: string; //'category' | 'translation';
  onCancel?: () => void;
  onOk?: () => void;
}

const CatalogsModal: React.FC<CatalogsModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  type = 'category',
  data,
}) => {
  const [formRef] = Form.useForm<CatalogFormBody>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const altLanguage = language === 'en' ? 'es' : 'en';
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (data && edit) {
      const compareLng = type === 'translation' ? altLanguage : language;
      const configuration = data?.configurations?.find(
        (row: CatalogConfig) => row.language === compareLng,
      );
      formRef.setFieldsValue({ ...data, ...configuration });
    } else {
      formRef.setFieldValue('language', data?._id ? altLanguage : language);
    }
  }, [edit, data, formRef]);

  const handleOnSubmit = async (values: CatalogFormBody) => {
    setLoading(true);
    const body = {
      configurations: [
        {
          name: values.name,
          slug: values.slug,
          language: values?.language,
        },
      ],
      field_config: values.field_config || data?.field_config,
    };

    if (data?._id) {
      body.configurations = data?.configurations || [];
      const index = data?.configurations.findIndex(
        (row: CatalogConfig) =>
          row.language === (type === 'category' ? language : altLanguage),
      );

      if (index !== -1) {
        body.configurations[index] = {
          name: values.name,
          slug: values.slug,
          language: values.language,
        };
      } else {
        body.configurations.push({
          name: values.name,
          slug: values.slug,
          language: values.language,
        });
      }
    }

    const response = data?._id
      ? await APIService.updateCatalog(data?._id as string, body)
      : await APIService.createCatalog(body);

    if (response.ok) {
      if (onOk) {
        onOk();
      }

      if (data?._id) {
        message.success(t('catalogs.updateSuccess'));
      } else {
        message.success(t('catalogs.createSuccess'));
      }

      formRef.resetFields();
      formRef.setFieldValue('language', language);
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      okButtonProps={{ loading }}
      okText={edit ? t('general.edit') : t('general.create')}
      onCancel={() => {
        onCancel?.();
        formRef.resetFields();
      }}
      onOk={() => formRef.submit()}
      open={visible}
      title={edit ? t('catalogs.edit') : t('catalogs.create')}
      width={700}
    >
      <CatalogForm formRef={formRef} onSubmit={handleOnSubmit} target={type} />
    </Modal>
  );
};
export default CatalogsModal;
